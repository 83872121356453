<template>
  <div>
    <el-menu
      class="el-menu-atf"
      :unique-opened="true"
      mode="horizontal"
      :default-active="activeIndex"
      menu-trigger="click"
      @select="selectMenu"
    >
      <template v-for="(item, index) in navData">
        <el-submenu
          :key="index"
          :index="item.path"
          v-if="item.children"
          popper-class="myHomeMenuPopper"
        >
          <template slot="title">
            {{ item.title }}
          </template>
          <template v-for="(ee, vv) in item.children">
            <el-submenu
              :key="vv"
              :index="ee.path"
              v-if="ee.children"
              popper-class="myHomeMenuPopper"
            >
              <template slot="title">
                {{ ee.title }}
              </template>
              <template v-for="(eee, vvv) in ee.children">
                <el-submenu
                  :key="vvv"
                  :index="eee.path"
                  v-if="eee.children"
                  popper-class="myHomeMenuPopper"
                >
                  <template slot="title">
                    {{ eee.title }}
                  </template>
                  <template v-for="(eeee, vvvv) in eee.children">
                    <el-menu-item :key="vvvv" :index="eeee.path">
                      {{ eeee.title }}
                    </el-menu-item>
                  </template>
                </el-submenu>
                <el-menu-item :key="vvv" v-else :index="eee.path">
                  {{ eee.title }}
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item :key="vv" :index="ee.path" v-else>
              {{ ee.title }}
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item :key="index" :index="item.path" v-else>
          {{ item.title }}
        </el-menu-item>
      </template>
    </el-menu>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialog">暂未开放</div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="isMumber"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible_company" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible_admin" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员操作入会</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "navMenuItem",
  data() {
    return {
      activeIndex: "/",
      dialogVisible: false,
      isMumber: false,
      navData: [
        {
          title: "网站首页",
          path: "/",
        },
        {
          title: "协会概况",
          path: "/association",
          children: [
            {
              title: "协会简介",
              path: "/survey",
            },
            {
              title: "协会章程",
              path: "/survey/constitution",
            },
            {
              title: "组织架构",
              path: "/survey/framework",
            },
            {
              title: "秘书处架构",
              path: "/survey/structure",
            },
            {
              title: "大事记",
              path: "/survey/memorabilia",
            },
          ],
        },
        {
          title: "政策法规",
          path: "/regulations",
          children: [
            {
              title: "中华人民共和国海关总署",
              path: "http://www.customs.gov.cn/",
            },
            {
              title: "中华人民共和国上海海关",
              path: "http://shanghai.customs.gov.cn/",
            },
            {
              title: "中华人民共和国商务部",
              path: "http://www.mofcom.gov.cn/",
            },
            {
              title: "中华人民共和国国家发展和改革委员会",
              path: "https://www.ndrc.gov.cn/",
            },
            {
              title: "中华人民共和国农业农村部",
              path: "http://www.moa.gov.cn/",
            },
            {
              title: "中国国际贸易单一窗口",
              path: "https://www.singlewindow.cn/",
            },
          ],
        },
        {
          title: "会员专区",
          path: "/member",
          children: [
            {
              title: "会员名录",
              path: "/memberDirectory",
              children: [
                {
                  title: "副会长单位",
                  path: "/member/memberDirectory/4",
                },
                {
                  title: "常务理事单位",
                  path: "/member/memberDirectory/21",
                },
                {
                  title: "理事单位",
                  path: "/member/memberDirectory/2",
                },
                {
                  title: "会员单位",
                  path: "/member/memberDirectory/1",
                },
              ],
            },
            {
              title: "入会申请",
              path: "/memberShip",
            },
            {
              title: "会员权益",
              path: "/interests",
              children: [
                {
                  title: "副会长单位权益",
                  path: "/member/interests/vice",
                },
                {
                  title: "常务理事单位权益",
                  path: "/member/interests/standing",
                },
                {
                  title: "理事单位权益",
                  path: "/member/interests/director",
                },
                {
                  title: "会员单位权益",
                  path: "/member/interests/member",
                },
              ],
            },
          ],
        },
        {
          title: "资讯中心",
          path: "/centre",
          children: [
            {
              title: "党建工作",
              path: "/party",
              children: [
                {
                  title: "党支部架构",
                  path: "/centre/party/branch",
                },
                {
                  title: "党支部新闻",
                  path: "/activitys?content_type=13",
                },
              ],
            },
            {
              title: "预归类工作",
              path: "/classification",
              children: [
                {
                  title: "协会预归类业务介绍",
                  path: "",
                },
                {
                  title: "预归类动态",
                  path: "/activitys?content_type=14",
                },
              ],
            },
            {
              title: "行业信息",
              path: "/information",
              children: [
                {
                  title: "行业大事件",
                  path: "/activitys?content_type=2&sub_type=1",
                },
                {
                  title: "行业调研",
                  path: "/activitys?content_type=2&sub_type=2",
                },
                {
                  title: "行业倡议",
                  path: "/activitys?content_type=2&sub_type=3",
                },
                {
                  title: "业务知识",
                  path: "/activitys?content_type=2&sub_type=4",
                },
                {
                  title: "竞赛资讯",
                  path: "/activitys?content_type=2&sub_type=5",
                },
                {
                  title: "荣誉公示",
                  path: "/activitys?content_type=2&sub_type=6",
                },
                // {
                //   title: '信用公示',
                //   path: "/activitys?content_type=2&sub_type=7",
                // },
              ],
            },
            {
              title: "协会动态",
              path: "/associationss",
              children: [
                {
                  title: "政协交流",
                  path: "/activitys?content_type=3&sub_type=1",
                },
                {
                  title: "协会会议",
                  path: "/activitys?content_type=3&sub_type=3",
                },
                {
                  title: "协会活动",
                  path: "/activitys?content_type=3&sub_type=4",
                },
                {
                  title: "分会工作",
                  path: "/activitys?content_type=3&sub_type=5",
                },
                {
                  title: "培训动态",
                  path: "/activitys?content_type=3&sub_type=6",
                },
              ],
            },
            {
              title: "协企交流",
              path: "/activitys?content_type=8",
            },
          ],
        },
        {
          title: "教育培训",
          path: "/college",
          children: [
            {
              title: "课程设置",
              path: "/college/courseSet",
            },
            // {
            //   title: "课程讲师",
            //   path: "/college/terchar",
            //   children: [
            //     {
            //       title: "讲师介绍",
            //       path: "/college/terchar/introduce",
            //     },
            //     {
            //       title: "讲师招募",
            //       path: "/college/terchar/recruit",
            //     },
            //   ],
            // },
            {
              title: "线上课程",
              path: "/college/course",
              // children: [
              //   {
              //     title: "公益课程",
              //     path: "/college/onLine/punlic",
              //   },
              //   {
              //     title: "报关实务课程",
              //     path: "/college/onLine/custom",
              //   },
              //   {
              //     title: "商品归类课程",
              //     path: "/college/onLine/commodity",
              //   },
              //   {
              //     title: "国际贸易课程",
              //     path: "/college/onLine/trade",
              //   },
              //   {
              //     title: "海关关税课程",
              //     path: "/college/onLine/tariff",
              //   },
              //   {
              //     title: "关税筹划课程",
              //     path: "/college/onLine/plan",
              //   },
              //   {
              //     title: "考前辅导课程",
              //     path: "/college/onLine/coach",
              //   },
              //   {
              //     title: "贸易合规课程",
              //     path: "/college/onLine/trading",
              //   },
              //   {
              //     title: "检验检疫课程",
              //     path: "/college/onLine/quarantine",
              //   },
              // ],
            },
            // {
            //   title: "课件获取",
            //   path: "/college/courseware",
            // },
          ],
        },
        {
          title: "人才专区",
          path: "/talents",
          children: [
            {
              title: "招聘信息",
              path: "/talents/recruitment",
            },
            {
              title: "人才库",
              path: "/talents/library",
            },
          ],
        },
        {
          title: "联系我们",
          path: "/contactUs",
          children: [
            {
              title: "协会联系方式",
              path: "/contactUs/association",
            },
            {
              title: "留言箱",
              path: "/contactUs/message",
            },
          ],
        },
      ],
      dialogVisible_company: false,
      dialogVisible_admin: false,
    };
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);
    },
  },
  mounted() {},
  methods: {
    selectMenu(index, path) {
      if (index.indexOf("http") !== -1) {
        window.open(index);
        return;
      }
      if (index === "/talents/library") {
        if (!this.USER_INFO || !this.USER_INFO.aila_no) {
          this.isMumber = true;
          return;
        }
      }
      if (index === "/memberShip") {
        if (!this.estimateUserInfo(true, true, !this.USER_INFO.is_admin)) {
          return;
        }
      }
      if (index == "") {
        this.dialogVisible = true;
        return;
      }
      this.$router.push(index);
    },
    estimateUserInfo(sign=true, company=true, is_admin=false){
      if (!this.USER_INFO && sign) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      }else if (!this.USER_INFO.company_id && company) {
        this.dialogVisible_company = true;
        return false;
      }else if (is_admin) {
        this.dialogVisible_admin = true;
        return false;
      }else {
        return true;
      }
    },
    toConsole() {
      this.$router.push({path:"/console/company/basic"})
    },
    handleActive(path) {
      if (path.indexOf("survey") !== -1) {
        this.activeIndex = path.substring(0, 7);
      } else {
        this.activeIndex = path;
      }
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push("/memberShip");
    },
  },
};
</script>

<style scoped lang="less">
/**
导航条的样式
*/
/deep/ .el-menu-atf {
  border-bottom: none;
}

/deep/ .el-menu {
  background: transparent;
}

/deep/ .submenu {
  width: 110px;
}

/deep/ .el-submenu__icon-arrow {
  color: #999 !important;
}

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: none;
}

/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #e3954f;
}

/deep/ .el-menu-item {
  padding: 0 10px;
  width: 96px;
  font-size: 16px;
}
li.el-menu-item {
  font-size: 16px;
}
/deep/ .el-submenu__title {
  width: 120px;
  font-size: 16px;
  padding: 0 10px;
}

.blue {
  color: #409eff;
  cursor: pointer;
}

.openDialogs {
  height: 100px;
  line-height: 100px;
}
.open {
  margin: 20px 0 0;
  i {
    color: #049DED;
    margin-left: 5px;
    border: 1px solid #049DED;
    border-radius: 50%;
  }
}
</style>
<style lang="less">
.myHomeMenuPopper {
  /deep/ .el-menu-item {
    font-size: 16px;
  }
}
</style>
