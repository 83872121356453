<template>
  <div class="siffaDefaultLayout">
    <navMenu></navMenu>
    <backPageTop></backPageTop>
    <router-view />
    <pageBottom class="bottomPosition"></pageBottom>
  </div>
</template>

<script>
import navMenu from "~scb/components/layouts/navMenu";
import pageBottom from "~scb/components/layouts/pageBottom";
import backPageTop from "~/baseComponents/backPageTop";
export default {
  components: { navMenu, pageBottom, backPageTop },
  mounted() {},
};
</script>

<style scoped lang="less">
.siffaDefaultLayout {
  background: #fafafa;
  position: relative;
  min-height: 100vh;
  padding-bottom: 350px;
}
.bottomPosition{
  position: absolute;
  bottom: 0;
}
</style>